export const mediumGray = "#474747" //not too dark, not too light
export const subtleBackgroundColor = "#fafafa" //very light gray color
export const lighterTeal = "#b0d6c6"
export const darkerTeal = "#73ba9d";

export const subheaderFontSize = 28; //+px
export const fontStyle = {
  fontFamily: "georgia,garamond,serif",
  fontSize: subheaderFontSize + "px",
  //display: "table-cell",
  //verticalAlign: "middle",
  //lineHeight:"35px",
  textAlign: "center",
  color: mediumGray,
}

//used in layout and landingPage
export const sectionPadding = 50

export const scrollToComponent = ({ ref }) => {
  console.log("scrollToComponent ref: ", ref)

  if (ref && ref.current) {
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
  }
}

export const ANDROID_DOWNLOAD_LINK =
  "https://play.google.com/store/apps/details?id=com.techmonsterco.recipetotable"
export const iOS_DOWNLOAD_LINK = "https://apps.apple.com/us/app/recipe-to-table/id1469083989?ls=1"
//"https://testflight.apple.com/join/4Srxw7Hi" 
