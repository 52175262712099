import React from "react";
import { Col, Row } from "antd";
import PropTypes from "prop-types";
import { scrollToComponent, subheaderFontSize } from "./statics";
import { Link } from "gatsby";
import { fontStyle as staticFontStyle } from "../components/statics";

const MyHeader = ({ headerScrollToLinks }) => {
  //console.log('MyHeader headerScrollToLinks?: ', JSON.stringify(headerScrollToLinks));
  return (
    <header
      style={{
        //background: `white`,
        display: "block",
        //if width of screen becomes very small, do not allow text to go under header bar
        minWidth: "200px",
        //padding helps vertically center the Header Text
        //paddingBottom: 10,
        backgroundColor: "white",
        //boxShadow line all the way across
        //boxShadow: '0 0px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 15px 0 rgba(0, 0, 0, 0.19)',
        //boxShadow on edges, no shadow
        boxShadow: "0px 13px 15px -20px rgba(0,0,0,0.75)",
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      {/*Debug breakpoints, here are pixel values: https://ant.design/components/grid/#Col*/}
      <Row
        type="flex"
        justify="space-around"
        align="middle" //vertically align child elements
        style={
          {
            //backgroundColor:'blue',
          }
        }
      >
        <Col
          xs={24}
          sm={24}
          md={6}
          lg={6}
          xl={4}
          style={
            {
              //backgroundColor:'orange',
            }
          }
        >
          <Link
            to="/"
            style={{
              //styles below were in <Col> tag, but need to be here to make Link Flex on small display right
              display: "flex",
              flexDirection: "row",
              //paddingLeft:25,
              height: 54,
              justifyContent: "center",
            }}
          >
            <h3 style={fontStyle2}>{`fork more`}</h3>
          </Link>
        </Col>
        <Col
          //span={8}
          xs={0}
          sm={0}
          md={6}
          lg={6}
          xl={8}
        />{" "}
        {/*Horizontal spacer*/}
        {/*Conditionally show these?*/}
        {headerScrollToLinks.map(element => {
          //console.log('element: ', element);
          return (
            <Col
              //span={3}
              xs={6}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              style={{
                marginTop: 9,
                marginBottom: 5,
                justifyContent: "center",
                //backgroundColor:'green' ,
                textAlign: "center", //col has a display value of 'block' so text align nested text values to center
              }}
            >
              {/*If there is a link path > go to that link gatsby way (link is ON this site so use <Link/>)
               else assumed this is a scrollToComponent > Scroll to the Referenced component on This web page
            todo (if necessary?) use href for external link
            */}
              {element.linkPath ? (
                <Link
                  to={element.linkPath}
                  //style={{...{linkStyle},...{alignSelf:'center'}}}
                  style={linkStyle}
                >
                  {element.linkText}
                </Link>
              ) : (
                <button type="button" onClick={() => scrollToComponent(element)} style={linkStyle}>
                  {element.linkText}
                </button>
              )}
            </Col>
          );
        })}
      </Row>
    </header>
  );
};

MyHeader.defaultProps = {
  headerScrollToLinks: [],
};

MyHeader.propTypes = {
  headerScrollToLinks: PropTypes.arrayOf(PropTypes.object),
};

const linkStyle = {
  fontSize: 16, //subheaderFontSize - 10,
  background: "none",
  border: "none",
  cursor: "pointer",
  color: "black",
  whiteSpace: "nowrap", //do not break if a word has a space on it, prevents 'how to' collapse 'early' into 2 rows on small width view port
  //backgroundColor:'blue',
};

const fontStyle2 = {
  ...staticFontStyle,
  fontSize: 35,
  fontStyle: "italic",
  //display: 'inline',
  fontFamily: "savoye",
  padding: 0,
  paddingTop: 17,
  paddingRight: 7,
  //color: "black",
  //backgroundColor:'orange',
};

export default MyHeader;
