/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "./layout.css"

import { Layout, Col, Row } from "antd"
import MyHeader from "./myHeader"
import { mediumGray, sectionPadding, subtleBackgroundColor, subheaderFontSize } from "./statics"

export const PRIVACY_LINK = "https://sites.google.com/view/tmcoprivacypolicy/"
export const EMAIL = "info@ForkMore.com"
const CONTACT_LINK = "mailto:" + EMAIL

const { Content: AntContent, Footer: AntFooter } = Layout

const fontStyle = {
  color: mediumGray,
  display: "inline-block",
};

const MyLayout = ({ children, headerScrollToLinks, showChat = false }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  //console.log('MyLayout headerScrollToLinks: ', JSON.stringify(headerScrollToLinks)); //debug

  return (
    <>
      <Layout
        style={{
          backgroundColor: subtleBackgroundColor,
         // height:"100vh",
          //backgroundColor:'red',
        }}
      >
        {/*Could have AntHeader but styling is not what we want, so removed*/}
        <MyHeader
          siteTitle={data.site.siteMetadata.title}
          //if content contains children
          //and children[1?] has a property: Array of Refs
          //those refs would be our 'scroll tos'
          //so should it be an array of [text, ref] objs ('linkText', 'ref' to scroll to)
          headerScrollToLinks={headerScrollToLinks}
        />

        {/*I assume we want to wrap in main tags because Gatsby had main tags */}
        <main
        style={{
           //paddingTop: 10,
        }}
        >
          <AntContent
            style={{
              //backgroundColor:subtleBackgroundColor,
              paddingBottom: sectionPadding,
              minHeight: "100vh", //on 404 page make background go all the way to bottom of view port
            }}
          >
            {children}
          </AntContent>
        </main>

        {/*I assume we want to wrap in footer tags because Gatsby had main tags*/}
        <footer
          style={
            {
              // backgroundColor: subtleBackgroundColor,
            }
          }
        >
          <AntFooter
            style={{
              //backgroundColor: subtleBackgroundColor, //this does not work as expected..
              paddingTop: 30,
              paddingBottom:30,
              paddingLeft: 5,
              paddingRight: 5,
            }}
          >
            <Row>
              <Col
                xs={24}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                align={"center"}
                style={
                  {
                    //backgroundColor:'yellow'
                  }
                }
              >
                <a
                  className="underlineOnHoover"
                  style={{...fontStyle,...{fontSize: subheaderFontSize - 13}}}
                  href={CONTACT_LINK}
                >
                  {EMAIL}
                </a>
              </Col>

              <Col
                xs={24}
                sm={10}
                md={10}
                lg={10}
                xl={10}
                //span={24}
                style={
                  {
                    //backgroundColor:'green',
                  }
                }
                align={"center"}
              >
                <p
                  align="center"
                  style={{...{fontStyle},...{marginBottom:0}}}
                >
                  © {new Date().getFullYear()} Tech Monster Co.{" "}
                </p>
              </Col>

              <Col
                //span={12}
                xs={24}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                style={
                  {
                    //backgroundColor:'blue'
                  }
                }
                align={"center"}
              >
                <a
                  className="underlineOnHoover"
                  style={{...fontStyle,...{fontSize: subheaderFontSize - 13}}}
                  href={PRIVACY_LINK}
                >
                  Privacy Policy
                </a>
              </Col>
            </Row>

            {/*TODO Include in legal page
        "Google Play and the Google Play logo are trademarks of Google LLC."
      */}
          </AntFooter>

        {/*HubSpot Customer Chat*/}
        {showChat && 
        <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/7245300.js"></script>
        }
        <meta name="p:domain_verify" content="eafc2e16732a1315bbf52efa9443d30e"/>
        
        </footer>
      </Layout>
    </>
  )
}

MyLayout.defaultProps = {
  headerScrollToLinks: [],
}

MyLayout.propTypes = {
  children: PropTypes.node.isRequired,
  headerScrollToLinks: PropTypes.arrayOf(PropTypes.object),
}

export default MyLayout
